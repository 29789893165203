import { render, staticRenderFns } from "./asiakastilin-edut.vue?vue&type=template&id=0e861d88"
import script from "./asiakastilin-edut.vue?vue&type=script&lang=ts"
export * from "./asiakastilin-edut.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/src/nuxt-app/components/BaseButton.vue').default})
